<template>
  <div
    v-if="details.length > 0"
    class="rounded-md text-white gap-x-6 bg-comeen-primary-400 hover:bg-comeen-primary-300 cursor-pointer py-3 mb-6 sm:px-3.5 max-h-32"
  >
    <div :class="changeCarouselCss()">
      <div v-if="isMultipleInformation && currentIndex !== 0">
        <vs-icon
          class="cursor-pointer w-1/12"
          size="small"
          :icon="faCaretLeft"
          @click.native="changeIndex(currentIndex - 1)"
          color="#d3e1f0"
        />
      </div>
      <div v-else class="w-1/12"></div>

      <template v-for="(item, index) in details">
        <div
          v-if="index === currentIndex"
          :key="item.id"
          @click="handleClick"
          class="w-10/12"
        >
          <div class="flex flex-row items-center justify-center cursor-pointer">
            <div class="flex justify-center">
              <span class="font-semibold text-base text-center">{{
                item.title
              }}</span>
            </div>
            <div>
              <vs-icon
                size="extrasmall"
                class="pl-4"
                :icon="faCircleInfo"
                color="#d3e1f0"
              />
            </div>
          </div>
          <vs-popup :title="item.title" v-model:active="isModalOpen">
            <span>{{ item.message }}</span>
          </vs-popup>
        </div>
      </template>

      <div v-if="isMultipleInformation && currentIndex !== details.length - 1">
        <vs-icon
          size="small"
          class="cursor-pointer w-1/12"
          :icon="faCaretRight"
          @click.native="changeIndex(currentIndex + 1)"
          color="#d3e1f0"
        />
      </div>
      <div v-else class="w-1/12"></div>
    </div>
  </div>
</template>

<script setup>
import {
  faCircleInfo,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/pro-solid-svg-icons'
</script>

<script>
export default {
  name: 'InformationBanner',
  data() {
    return {
      isModalOpen: false,
      currentIndex: 0,
    }
  },
  props: {
    details: {
      type: Array,
      default: () => [],
      // this array must contain objects with at least "title" and "message" as keys
    },
    date: {
      type: Date,
      required: true,
    },
  },
  computed: {
    isMultipleInformation() {
      return this.details.length > 1
    },
  },
  watch: {
    date() {
      this.currentIndex = 0
    },
  },
  methods: {
    changeCarouselCss() {
      if (this.isMultipleInformation) {
        return 'flex flex-row justify-between items-center'
      } else {
        return 'flex flex-row justify-center items-center'
      }
    },
    changeIndex(index) {
      this.currentIndex = index
    },
    handleClick() {
      this.isModalOpen = true
    },
  },
}
</script>
